import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { type ChangeEvent, useCallback, useState, useEffect } from 'react';
import { LinkedIn } from '~/components/static/LinkedIn';
import { Checkbox } from '~/components/ui/Checkbox';
import { env } from '~/env';
import { api } from '~/utils/api';

function extractLinkedInProfileId (value: string): string {
  const input = value.toLowerCase();
  // Regular expression to match the profile ID in a URL after '/in/'
  // Updated to include special characters
  const regexUrl = /\/in\/([a-zA-Z0-9-æøåÆØÅ]+)(\/|\?|$)/;
  // Regular expression to validate a standalone profile ID
  // Also updated to include special characters
  const regexProfileId = /^[a-zA-Z0-9-æóíáéøåÆØÅ]+$/;

  // First try to match as a part of a URL
  let matches = input.match(regexUrl);
  if (matches?.[1]) {
    return matches[1];
  }

  // If no URL match, check if the input itself is a valid profile ID
  matches = input.match(regexProfileId);
  if (matches) {
    return input; // The input itself is the profile ID
  }

  // Return null if no profile ID is found
  return '';
}

export const LinkedInInput: React.FC = () => {
  const [identifier, setIdentifier] = useState('');
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);

  const { push } = useRouter();

  const { mutateAsync: generateProfile, isPending } = api.initialisation.generateProfile.useMutation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIdentifier(event.target.value || '');
  }, []);

  const handleGenerateProfile = useCallback(async () => {
    const transformedIdentifier = extractLinkedInProfileId(identifier);

    const { public_identifier } = await generateProfile({ identifier: transformedIdentifier, agreeWithTerms });

    if (public_identifier) {
      push(`/profile/${public_identifier}`);
    }
  }, [identifier, agreeWithTerms, generateProfile, push]);

  useEffect(() => {
    console.log(identifier);
  }, [identifier]);

  return (
    <>
      <div className="relative">
        <LinkedIn className="absolute top-[20px] left-8 pointer-events-none " />
        <p className="absolute hidden lg:block top-[20px] left-16 pointer-events-none">https://www.linkedin.com/in/</p>
        <input value={identifier} type="text" onChange={handleChange} autoFocus placeholder="linkedin-profile" className="w-full bg-grey rounded-full h-[64px] pl-[72px] lg:pl-[281px]" />
      </div>
      <div className="flex flex-col my-2">
        <p className="font-light text-center opacity-60 text-sm">*This is not the product, simply a glimpse of our product and it&apos;s possibilities</p>
      </div>
      <div className="flex flex-col justify-center mt-4 gap-2">
        <div className="block lg:flex flex-row flex-1 justify-center items-center font-light text-center text-text text-sm">
          <span className="px-1">I agree to the</span>
          <Link className="cursor-pointer text-purple underline" href={`${env.NEXT_PUBLIC_BRANDING_URL}/terms-of-service`} target="_blank">Terms of Service</Link>
          <span className="px-1">and</span>
          <Link className="cursor-pointer text-purple underline" href={`${env.NEXT_PUBLIC_BRANDING_URL}/privacy-policy`} target="_blank">Privacy Policy</Link>
          <Checkbox isChecked={agreeWithTerms} onChange={() => setAgreeWithTerms(!agreeWithTerms)} />
        </div>
        <div className="flex justify-center">
          <button disabled={isPending || !agreeWithTerms} onClick={handleGenerateProfile} className={classNames('rounded-full text-white py-4 px-8 inline', {
            'bg-primary': !isPending && agreeWithTerms,
            'bg-darkgrey': isPending || !agreeWithTerms,
          })}>Scan profile</button>
        </div>
      </div>
    </>
  );
};
