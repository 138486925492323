import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    DATABASE_URL: z
      .string()
      .url()
      .refine(
        (str) => !str.includes('YOUR_MYSQL_URL_HERE'),
        'You forgot to change the default URL',
      ),
    PROXY_CURL_API_KEY: z.string(),
    OPENAI_API_KEY: z.string(),
    POSTGRES_HOST: z.string(),
    POSTGRES_DATABASE: z.string(),
    POSTGRES_USER: z.string(),
    POSTGRES_PASSWORD: z.string(),
    POSTGRES_USE_SSL: z.coerce.boolean(),
    SLACK_INCOMMING_WEBHOOK: z.string(),
    SNOWFLAKE_USER: z.string(),
    SNOWFLAKE_ROLE: z.string(),
    SNOWFLAKE_PASSWORD: z.string(),
    SNOWFLAKE_ACCOUNT: z.string(),
    SNOWFLAKE_WAREHOUSE: z.string(),
    SNOWFLAKE_DATABASE: z.string(),
    SNOWFLAKE_SCHEMA: z.string(),
    SNOWFLAKE_REGION: z.string(),
    SNOWFLAKE_APPLICATION: z.string(),
    GPT_MODEL_NAME: z.string(),
    MONGO_CONNECTION_STRING: z
      .string(),
    MONGO_DATABASE: z
      .string(),
    NODE_ENV: z
      .enum(['development', 'test', 'production'])
      .default('development'),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    // NEXT_PUBLIC_CLIENTVAR: z.string(),
    NEXT_PUBLIC_BRANDING_URL: z.string(),
    NEXT_PUBLIC_QUICK_MODE: z.coerce.boolean(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    DATABASE_URL: process.env.DATABASE_URL,
    PROXY_CURL_API_KEY: process.env.NODE_ENV,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    MONGO_CONNECTION_STRING: process.env.MONGO_CONNECTION_STRING,
    MONGO_DATABASE: process.env.MONGO_DATABASE,
    POSTGRES_HOST: process.env.POSTGRES_HOST,
    POSTGRES_DATABASE: process.env.POSTGRES_DATABASE,
    POSTGRES_USER: process.env.POSTGRES_USER,
    POSTGRES_PASSWORD: process.env.POSTGRES_PASSWORD,
    POSTGRES_USE_SSL: process.env.POSTGRES_USE_SSL,
    SLACK_INCOMMING_WEBHOOK: process.env.SLACK_INCOMMING_WEBHOOK,
    SNOWFLAKE_USER: process.env.SNOWFLAKE_USER,
    SNOWFLAKE_ROLE: process.env.SNOWFLAKE_ROLE,
    SNOWFLAKE_PASSWORD: process.env.SNOWFLAKE_PASSWORD,
    SNOWFLAKE_ACCOUNT: process.env.SNOWFLAKE_ACCOUNT,
    SNOWFLAKE_WAREHOUSE: process.env.SNOWFLAKE_WAREHOUSE,
    SNOWFLAKE_DATABASE: process.env.SNOWFLAKE_DATABASE,
    SNOWFLAKE_SCHEMA: process.env.SNOWFLAKE_SCHEMA,
    SNOWFLAKE_REGION: process.env.SNOWFLAKE_REGION,
    SNOWFLAKE_APPLICATION: process.env.SNOWFLAKE_REGION,
    GPT_MODEL_NAME: process.env.GPT_MODEL_NAME,
    NEXT_PUBLIC_BRANDING_URL: process.env.NEXT_PUBLIC_BRANDING_URL,
    NEXT_PUBLIC_QUICK_MODE: process.env.NEXT_PUBLIC_QUICK_MODE,
    // NEXT_PUBLIC_CLIENTVAR: process.env.NEXT_PUBLIC_CLIENTVAR,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  /**
   * Makes it so that empty strings are treated as undefined.
   * `SOME_VAR: z.string()` and `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true,
});
