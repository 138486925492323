import Link from 'next/link';
import { ProfileScan } from '~/components/features/scan/ProfileScan';
import { SharedHead } from '~/components/shared/Head';
import { EverdayLogo } from '~/components/static/EverdayLogo';
import { InformationList } from '~/components/ui/InformationList';
import { env } from '~/env';

export default function Home () {
  return (
    <>
      <SharedHead />
      <main className="flex w-screen h-screen flex-col lg:flex-row bg-teal lg:items-center lg:justify-center">
        <div className="flex lg:flex-1 bg-white lg:h-full py-10 lg:py-40 px-10">
          <div className="flex flex-1 flex-col justify-center lg:gap-2 px-4">
            <div className="flex flex-col items-center lg:items-start">
              <Link href={env.NEXT_PUBLIC_BRANDING_URL} target="_blank">
                <EverdayLogo className="lg:mx-12 w-[200px]" />
              </Link>
              <div className="lg:hidden flex flex-col items-center mt-4 lg:mt-8">
                <h4 className="text-blue font-semibold text-baselg:mt-0">Skill analyzer</h4>
                <h1 className="max-w-[760px] text-2xl lg:text-6xl text-center font-bold mb-20">
                Identify your personal <span className="after:bg-secondary">skill gaps</span> with a click
                </h1>
              </div>
            </div>
            <InformationList
              items={[
                {
                  title: 'How it works?',
                  description: 'Skill analyzer scans your LinkedIn profile or your resume and we turn that into learning experience!',
                },
                {
                  title: 'What will I get from it?',
                  description: 'You will get an estimate of the skills and skill gaps from the profile you scan based on the european skill taxonomy.',
                },
                {
                  title: 'Who\'s account can I scan?',
                  description: 'You can scan your personal or your colleague\'s profiles, or both. It\'s free so you can go wild! But if you really want to scan entire teams please get in touch with us, so we can provide you with the best solution.',
                },
                {
                  title: 'How can I use this report?',
                  description: 'Very soon you will be able to scan entire teams or organisations, but you can already imagine how streamlined your skill maps creation can be! With your entire organisation\'s skills mapped you can create better succession plans, improve internal mobility and increase job satisfaction.',
                },
              ]}
            />
          </div>
        </div>
        <div className="flex w-full lg:w-[66%] bg-teal h-full">
          <div className="flex flex-1 flex-col justify-center items-center">
            <div className="hidden lg:flex flex-col items-center">
              <h4 className="text-blue font-semibold text-baselg:mt-0">Skill analyzer</h4>
              <h1 className="max-w-[760px] text-2xl lg:text-6xl text-center font-bold mb-20">
                Identify your personal <span className="after:bg-secondary">skill gaps</span> with a click
              </h1>
            </div>
            <ProfileScan />
          </div>
        </div>
      </main>
    </>
  );
}
