import { useState } from 'react';
import { Shape } from '~/components/static/Shape';
import { Tabs } from '~/components/ui/Tabs';
import { LinkedInInput } from './components/LinkedInInput';
import { ResumeInput } from './components/ResumeInput';

export const ProfileScan: React.FC = () => {
  const [activeTab, setActiveTab] = useState('LinkedIn');

  return (
    <div className="flex w-full relative mb-20 z-10 justify-center items-center lg:px-10 xl:px-40">
      <Shape className="absolute -bottom-[30px] lg:-bottom-[40px] -z-10 left-[50%] -translate-x-[50%]" />
      <div className="flex flex-1 flex-col bg-white lg:border w-full lg:w-auto lg:border-grey lg:rounded-xl pt-6 pb-12 px-10 lg:py-10 lg:px-20  gap-2 z-20">
        <div className="flex justify-center items-center flex-1 w-full mb-4">
          <Tabs tabs={['LinkedIn', 'Resume']} activeTab={activeTab} onChange={setActiveTab} />
        </div>
        <div>
          {activeTab === 'LinkedIn' ? <LinkedInInput /> : <ResumeInput />}
        </div>
      </div>
    </div>
  );
};
