interface ShapeProps {
  className?: string;
}

export const Shape: React.FC<ShapeProps> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 903 184" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 91.8C0 41.1032 41.0979 0 91.7947 0V183.6C41.0979 183.6 0 142.497 0 91.8Z" fill="#EF476F"/>
      <path d="M91.7969 91.8C91.7969 41.1003 132.897 0 183.597 0V183.6C132.897 183.6 91.7969 142.5 91.7969 91.8Z" fill="#FFE381"/>
      <rect x="183.598" width="183.6" height="183.6" rx="91.8" fill="#00A1E4"/>
      <path d="M367.199 91.8C367.199 41.1003 408.299 0 458.999 0C509.699 0 550.799 41.1003 550.799 91.8V183.6H458.999C408.299 183.6 367.199 142.5 367.199 91.8Z" fill="#7047EB"/>
      <rect x="550.797" width="183.6" height="183.6" rx="91.8" fill="#FFE381"/>
      <path d="M902.791 183.6C801.392 183.6 719.191 101.4 719.191 7.55019e-05L902.791 9.15527e-05L902.791 183.6Z" fill="#C3F7F3"/>
    </svg>
  );
};
