import classNames from 'classnames';

interface TabsProps {
  tabs: string[];
  activeTab: string;
  onChange: React.Dispatch<string>
}

export const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onChange }) => {
  return (
    <div className="flex gap-4">
      {tabs.map((tab) => (
        <div key={tab}
          className={classNames('flex text-bold border-b-2 px-2 cursor-pointer', {
            'border-purple font-semibold': activeTab === tab,
            'border-grey': activeTab !== tab,
          })}
          onClick={() => onChange(tab)}
        >
          <p>{tab}</p>
        </div>
      ))}
    </div>
  );
};
