interface InformationListProps {
  items: {
    title: string;
    description: string;
  }[];
}

export const InformationList: React.FC<InformationListProps> = ({ items }) => {
  return (
    <div className="flex flex-col gap-6">
      {items.map(({ title, description }, index) => (
        <div key={title} className="flex gap-4">
          <div className="w-[40px] h-[40px] rounded-full text-white bg-pink flex justify-center items-center">
            <span className="text-md font-bold">{index + 1}</span>
          </div>
          <div className="flex flex-1 flex-col gap-1">
            <h3 className="font-bold">{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
