import classNames from 'classnames';

interface IndicatorProps {
  className?: string;
}

export const Indicator: React.FC<IndicatorProps> = ({ className }) => {
  return (
    <div className={classNames('lds-grid', className)}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  );
};
