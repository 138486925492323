import { type ChangeEvent, useCallback, useState, useEffect } from 'react';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { upload } from '@vercel/blob/client';
import { Checkbox } from '~/components/ui/Checkbox';
import { env } from '~/env';
import { api } from '~/utils/api';
import { Indicator } from '~/components/ui/Indicator';

export const ResumeInput: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [identifier, setIdentifier] = useState('');

  const { push } = useRouter();

  const { mutateAsync: generateProfileFromResume } = api.initialisation.generateProfileFromResume.useMutation({ retry: false });
  const { data: linkedInProfile } = api.initialisation.doesLinkedInProfileExist.useQuery({ identifier }, { refetchInterval: 1000, enabled: !!identifier });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFiles(event.target.files);
  }, []);

  const handleGenerateProfileFromResume = useCallback(async () => {
    try {
      setIsLoading(true);

      const file = files?.[0];

      if (!file) throw new Error('No file selected');

      const newBlob = await upload(file.name, file, {
        access: 'public',
        handleUploadUrl: '/api/upload',
      });

      const { public_identifier } = await generateProfileFromResume({ url: newBlob.url, agreeWithTerms });

      setIdentifier(public_identifier);
    } finally {
      setIsLoading(false);
    }
  }, [agreeWithTerms, files, generateProfileFromResume]);

  useEffect(() => {
    if (linkedInProfile) {
      if (linkedInProfile.experiences?.length) {
        push(`/profile/${linkedInProfile.public_identifier}`);
      } else {
        setIdentifier('');
      }
    }
  }, [linkedInProfile, push]);

  return (
    <>
      {!identifier
        ? (
          <>
            <div className="relative">
              <DocumentTextIcon className="absolute top-[20px] left-8 pointer-events-none font-md w-[24px]" />
              <input accept="application/pdf" multiple={false} type="file" onChange={handleChange} autoFocus placeholder="file" className="w-full bg-grey border-2 border-purple rounded-full py-[16px] px-[80px]" />
            </div>
            <div className="flex flex-col my-2">
              <p className="font-light text-center opacity-60 text-sm">*This is not the product, simply a glimpse of our product and it&apos;s possibilities</p>
            </div>
            <div className="flex flex-col justify-center mt-4 gap-2">
              <div className="block lg:flex flex-row flex-1 justify-center items-center font-light text-center text-text text-sm">
                <span className="px-1">I agree to the</span>
                <Link className="cursor-pointer text-purple underline" href={`${env.NEXT_PUBLIC_BRANDING_URL}/terms-of-service`} target="_blank">Terms of Service</Link>
                <span className="px-1">and</span>
                <Link className="cursor-pointer text-purple underline" href={`${env.NEXT_PUBLIC_BRANDING_URL}/privacy-policy`} target="_blank">Privacy Policy</Link>
                <Checkbox isChecked={agreeWithTerms} onChange={() => setAgreeWithTerms(!agreeWithTerms)} />
              </div>
              <div className="flex justify-center">
                <button disabled={isLoading || !agreeWithTerms} onClick={() => handleGenerateProfileFromResume()} className={classNames('rounded-full text-white py-4 px-8 inline', {
                  'bg-primary': !isLoading && agreeWithTerms,
                  'bg-darkgrey': isLoading || !agreeWithTerms,
                })}>Scan profile</button>
              </div>
            </div>
          </>
        )
        : (
          <div className="flex flex-1 justify-center text-blue">
            <Indicator />
          </div>
        )}
    </>
  );
};
