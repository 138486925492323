import Head from 'next/head';

export const SharedHead = () => (
  <Head>
    <title>Everday - Skill analyzer</title>
    <meta name="description" content="Get powerful insights about your organization's skills, and prepare your teams for ever-changing work requirements" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta property="og:title" content="Everday - Skill analyzer" />
    <meta property="og:description" content="Get powerful insights about your organization's skills, and prepare your teams for ever-changing work requirements" />
    <meta property="og:image" content="/opengraph.png" />
    <meta property="og:url" content="https://example.com/page-url" /> {/* Replace with your actual URL */}
    <meta property="og:type" content="website" /> {/* or "article", "video", etc. based on your content */}
    <meta name="twitter:card" content="summary_large_image" /> {/* Twitter card type */}
    <link rel="icon" href="/favicon.ico" />
  </Head>
);
